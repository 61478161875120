import React from "react"
import { UsePageContext } from "../context/page-context"
import { useState } from "react"
import styled from "@emotion/styled"
import Seo from "../components/seo"

const Root = styled.div`
  padding: 40px 0 35px 0;
  display: flex;
  align-items: center;
`
const StyledText = styled.h1`
  margin-bottom: 0;
`

const NotFoundPage = () => {
  const { setIsAtContactPage, setIsAtNotfoundPage } = UsePageContext()
  useState(
    () => {
      setIsAtContactPage(false)
      setIsAtNotfoundPage(true)
    }
  )

  return(
    <>
      <Seo title="404:Not found" />
      <Root>
        <StyledText>404:Not found</StyledText>
      </Root>
    </>
  )
}

export default NotFoundPage
